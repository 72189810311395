@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

input {
  height: 50px;
  padding:0 16px
}

.empty-rank-container p {
  opacity: 0;
}

.empty-rank-container:hover p {
  opacity: 1;
}

.rank-container .text-xs {
  opacity: 0;
}

.rank-container:hover .text-xs  {
  opacity: 1;
}


.file-drop-area {
  background-color: #00000010;
  border: 1.5px solid #00000020;
  transition: 300ms ease-in-out;
  padding: 12px;
  cursor: pointer;
}

.file-drop-area:hover {
  background-color: #00000020;
  border: 1.5px solid #00000050;
}

.uploaded-file {
  padding: 4px;
  position: relative;
}

.uploaded-file:hover {
  /*background-color: var(--base-white-20);*/
}

.uploaded-file div {
  opacity: 0;
  cursor: pointer;
  color: white;
  transition: 300ms ease-in-out;
  transform: translateY(20px);
}

.uploaded-file:hover div {
  opacity: 1;
  transform: translateY(0px);
}
